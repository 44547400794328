<template>
  <div class="mb-4">
        <b-row v-if="errors.length > 0" class="mt-2">
            <b-col cols="12">
                
      <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key="index" variant="danger" dismissible>
        {{item.message}}</b-alert>
            </b-col>
            
        </b-row>
        
        <b-row v-if="showNoLocationAvailable == true" class="mt-2">
            <b-col cols="12">
                
      <b-alert :show="true" variant="warning" dismissible>No devices available to load data</b-alert>
            </b-col>
            
        </b-row>
        <b-row v-if="isLoading" align-h="center" class="mt-5" align-v="center">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner"></b-spinner>
        </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12" lg="4">
        <b-card class="secondary-background stack-info-card-4 clickable" @click="goToPage('/battery', 'battery')">
          <b-row>
            <b-col cols="8">
              <h2 v-if="totalDashboardInfoTotal() == 0">N/A</h2>
              <h2 v-if="totalDashboardInfoTotal() > 0">{{dashboardInfo.batteryPercentage}}%</h2>
              <p class="emphasize">Battery Percentage</p>
            </b-col>
            <b-col cols="4" class="text-center">
              <img src="@/assets/car-battery-opacity.svg" alt="" style="width: 100%;max-height: 117px" >
            </b-col>
          </b-row>
        </b-card>

      </b-col>
      <b-col cols="12" lg="4">
        <b-card class="secondary-background stack-info-card-4">
          <b-row>
            <b-col cols="8">
              <h2 v-if="totalDashboardInfoTotal() == 0">N/A</h2>
              <h2 v-if="totalDashboardInfoTotal() > 0">{{dashboardInfo.wattsGenerated}}%</h2>
              <p class="emphasize">kWh Generated Today</p>
            </b-col>
            <b-col cols="4" class="text-center">
              <img src="@/assets/arrow-up-solid.svg" alt="" style="width: 100%; max-height: 117px" >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4">
        <b-card class="secondary-background stack-info-card-4">
          <b-row>
            <b-col cols="8">
              <h2 v-if="totalDashboardInfoTotal() == 0">N/A</h2>
              <h2 v-if="totalDashboardInfoTotal() > 0">{{dashboardInfo.wattsUsed}}%</h2>
              <p class="emphasize">kWh Used Today</p>
            </b-col>
            <b-col cols="4" class="text-center">
              <img src="@/assets/arrow-down-solid.svg" alt="" style="width: 100%; max-height: 117px" >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12" xl="6">
        <b-card class="home-chart-display text-center">
          <label class="mt-0 info-label">Total kWh Generated Today</label>
          <b-skeleton-img v-if="isLoading" class=""></b-skeleton-img>
          <pie-chart v-if="!isLoading && totalDashboardInfoTotal() > 0" :data="chartData" :options="chartOptions" :height="340"
            class="graph-display"></pie-chart>
          <b-row align-h="center" align-v="center" class="fill-parent mt-0"
            v-if="!isLoading && totalDashboardInfoTotal() == 0">
            <span class="no-data-available">No data available for selected device</span>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xl="6" class="pl-0 pr-0 m-auto">
        <b-row no-gutters align-h="end" class="pl-3 pr-3 mt-0 ">
          <span class="top-section-info mb-1">Last Active: {{lastEventDate !== null ? lastEventDate : 'Not Found' | shortDateTimeFilter}}</span>
        </b-row>
        <b-row class="mt-0">
          <b-col cols="12" md="12" lg="6" class="top-section-info">
            <b-card class="gray-background text-center clickable" @click="goToPage('/summary', 'summary')">
              <h4>{{totalDashboardInfoLive()}} kW</h4>
              <p class="emphasize">Live</p>
              <h4>{{totalDashboardInfoTotal()}} kWh</h4>
              <p class="emphasize">Total</p>
              <p class="info-label">Summary</p>
            </b-card>
          </b-col>
          <b-col cols="12" md="12" lg="6" class="top-section-info">
            <b-card class="primary-background text-center clickable" @click="goToPage('/solar', 'solar')">
              <h4>{{dashboardInfo.solarInfo.live}} kW</h4>
              <p class="emphasize">Live</p>
              <h4>{{dashboardInfo.solarInfo.total}} kWh</h4>
              <p class="emphasize">Total</p>
              <p class="info-label">Solar</p>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-0">
          <b-col cols="12" md="12" lg="6" class="mt-4">
            <b-card class="tertiary-background text-center clickable" @click="goToPage('/generator', 'generator')">
              <h4>{{dashboardInfo.generatorInfo.live}} kW</h4>
              <p class="emphasize">Live</p>
              <h4>{{dashboardInfo.generatorInfo.total}} kWh</h4>
              <p class="emphasize">Total</p>
              <p class="info-label">Generator</p>
            </b-card>
          </b-col>
          <b-col cols="12" md="12" lg="6" class="mt-4">
            <b-card class="secondary-background text-center clickable" @click="goToPage('/grid', 'grid')">
              <h4>{{dashboardInfo.gridInfo.live}} kW</h4>
              <p class="emphasize">Live</p>
              <h4>{{dashboardInfo.gridInfo.total}} kWh</h4>
              <p class="emphasize">Total</p>
              <p class="info-label">Grid</p>
            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex'
  import PieChart from "@/components/PieChart.js";
  import {
    bus
  } from '../main';

  export default {
    name: 'Home',
    components: {
      PieChart
    },
    data: () => ({
      chartOptions: {
        hoverBorderWidth: 20
      },
      lastEventDate: null,
      chartData: {},
      isLoading: true,
      errors: [],
      showNoLocationAvailable: false
      // currentLocation: -1
    }),
    created() {

      bus.$on('changedLocation', () => {
        this.doApiCall()
      })

      this.doApiCall()

    },
    methods: {
      ...mapActions(['getDashboardInfo', 'getLoggedInLocations']),
      totalDashboardInfoTotal() {
        if (this.dashboardInfo != null) {
          let total = parseInt(this.dashboardInfo.gridInfo.total) + parseInt(this.dashboardInfo.generatorInfo.total) + parseInt(this.dashboardInfo.solarInfo.total)
          return total.toFixed(2)
        }
        return 0
      },
      totalDashboardInfoLive() {
        if (this.dashboardInfo != null) {
          let total = parseInt(this.dashboardInfo.gridInfo.live) + parseInt(this.dashboardInfo.generatorInfo.live) + parseInt(this.dashboardInfo.solarInfo.live)
          return total.toFixed(2)
        }
        return 0
      },
      doApiCall() {

        this.isLoading = true
            this.errors = []
        if (localStorage.getItem('selectedLocationId') === null) {
          this.getLoggedInLocations()
            .then(() => {
              // this.currentLocation = this.locations[0].untId
              if (localStorage.getItem('selectedLocationId') !== null) {
                this.locations.forEach(item => {
                  if (item.untId.toString() === localStorage.getItem('selectedLocationId').toString()) {
                    this.$store.commit('changeLocation', item)
                  }
                });
              }else
              {
                this.$store.commit('changeLocation', this.locations[0])
              }
              localStorage.getItem('selectedLocationId')
              this.callForInfo()
            })
            .catch(() => {
              this.errors.push({
                message: 'Failed to load locations. Please refresh and try again'
              })
            })
        } else {
          this.callForInfo()
        }
      },
      callForInfo() {
          this.errors = []
          this.lastEventDate = null
          this.getDashboardInfo()
          .then((response) => {
            if (response.data !== null) {
              
            let infoLabels = []
            let colours = []
            let info = []
            response.data.info.forEach(item => {
              if (item.name == 'ES_Generator') {
                infoLabels.push('Generator')
                colours.push('#EEB045')
                info.push(item.total)
                this.dashboardInfo.generatorInfo.total = item.total.toFixed(2)
                this.dashboardInfo.generatorInfo.live = item.live.toFixed(2)
              }
              if (item.name == 'ES_Solar') {
                infoLabels.push('Solar')
                colours.push('#65BC6E')
                info.push(item.total)
                this.dashboardInfo.solarInfo.total = item.total.toFixed(2)
                this.dashboardInfo.solarInfo.live = item.live.toFixed(2)
              }
              if (item.name == 'ES_Grid') {
                infoLabels.push('Grid')
                colours.push('#38A3A5')
                info.push(item.total)
                this.dashboardInfo.gridInfo.total = item.total.toFixed(2)
                this.dashboardInfo.gridInfo.live = item.live.toFixed(2)
              }
            });

            this.chartData = {
              hoverBackgroundColor: "red",
              hoverBorderWidth: 10,
              labels: infoLabels,
              datasets: [{
                label: "Data One",
                backgroundColor: colours,
                data: info
              }]
            }

            this.dashboardInfo.wattsUsed = response.data.percentageWattsUsed.toFixed(0)
            this.dashboardInfo.wattsGenerated = response.data.percentagewattsGenerated.toFixed(0)
            this.dashboardInfo.batteryPercentage = response.data.batteryPercentile.toFixed(1)

            this.lastEventDate = response.data.lastEventDate

            }
            else{
              this.showNoLocationAvailable = true
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
            this.errors.push({
              message: 'Failed to load data. Please refresh and try again.'
            })
          })
      },
      goToPage(gotoPath, location){
        this.$router.push({path: gotoPath})
      this.$store.commit('changeScreen', location)
      }
    },
    computed: {
      ...mapState(['dashboardInfo', 'selectedLocation', 'locations'])
    }
  }
</script>